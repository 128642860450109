@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Nunito Sans' !important;
}

.scrollbar::-webkit-scrollbar {
	width: 10px;
	height: fit-content;
	display: auto;
}

.scrollbar::-webkit-scrollbar-track {
	border-radius: 32px;
	background: #e8e8e8;
}

.scrollbar::-webkit-scrollbar-thumb {
	background: #ffaa07;
	border-radius: 32px;
}

.bg-inactive {
  background: rgba(206, 206, 206, 1);
}
.border-thin {
  border: 1px solid rgba(144, 144, 144, 1);
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.leading-22px {
  line-height: 22px;
}
.pt-90vh {
  padding-top: 90vh;
}
.w-235px {
  width: 235px;
}
.text-10px {
  font-size: 10px;
}
.equal-sign {
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  background-color: #E7E7E7;
}
.fed-card-approve-button {
  position: absolute;
  bottom: -16px;
  left: calc(50% - 8rem);
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.subtle-dashed-border-bottom {
  border-bottom: 1.5px dashed #919191;
  padding-bottom: 24px;
}
.modal-popup-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
.input-normal {
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(206, 206, 206, 1);
  outline: none;
}
.btn-normal {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgba(69, 69, 69, 1);
}
.bg-oro-gradient {
  background: linear-gradient(180deg, #f9ca36 0%, #f8b830 100%);
}
.non-active-log {
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.active-log {
  width: 48px;
  height: 48px;
  font-size: 22px;
  border-width: 3.5px;
  line-height: calc(48px - 7px);
}
.arrow-head {
  width: 104px;
  margin-top: -5.5rem;
}
.btn-dark {
  background: linear-gradient(180deg, #454545 0%, #454545 100%);
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn-cta {
  background: linear-gradient(180deg, #f9ca36 0%, #f8b830 100%);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 9999px;
}
.btn-cta:focus {
  outline: none;
}
.btn-normal:focus {
  outline: none;
}
.btn-medium-yellow:focus {
  outline: none;
}

.tag-font {
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.small-label {
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: rgb(158, 157, 157);
}
.text-header-1 {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 87px;
}
.text-header-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
}
.input-border {
  border: '1px solid rgba(206, 206, 206, 1)';
}
.card-border {
  border: 1px solid rgba(206, 206, 206, 1);
}

.btn-medium-yellow {
  background-color: #ffe1a8;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.text-header-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}
.text-body-large {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}
.text-body-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(144, 144, 144, 1);
  text-transform: uppercase;
}
.text-body-bold {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px;
}
.text-body-italic {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.text-small {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.text-small-bold {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
}
.text-eyebrow {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  text-transform: uppercase;
}
.text-button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.text-link {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
}
.text-bold-button {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}
.text-small-label {
  font-style: normal;
  font-weight: 800;
  font-size: 8px;
  line-height: 11px;
  /* identical to box height */

  text-transform: uppercase;
}
.text-medium-body {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
.text-cta {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
.text-subtle {
  color: rgb(158, 157, 157);
}
.text-inactive {
  color: rgba(206, 206, 206, 1);
}
.menuBar {
  width: 280px;
  height: 100vh;
  overflow: scroll;
}
.oro-yellow-box-border {
  border: 1px solid #FFC351;
  box-shadow: 0px 0px 0px 3px rgba(249, 186, 72, 0.2);
  border-radius: 4px;
}
.menuPageContainer {
  width: calc(100% - 280px);
}
.scrollArea {
  height: calc(100vh - 65px);
  overflow: scroll;
}
.dashboardLogo {
  width: 180px;
  height: 65px;
  background: #ccc;
  margin: 0 auto;
  /* margin-top: 10px; */
}

.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  /* width: 168px; */
  width: 100%;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 26px;
}

.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item:hover
  .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565 !important;
}

.ant-modal-body {
  padding: 32px;
}

.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: white;
}

.ant-upload-list-item-name {
  cursor: pointer;
}

.react-images__footer__caption {
  font-size: 20px;
  /* background-color: #ffffff50; */
  background-color: #00000050;
  padding: 10px;
  border-radius: 3px;
}

.spinner {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.ant-input-number-input-wrap {
  width: 100%;
}

thead > tr > th {
  background-color: #16a085 !important;
  color: white !important;
}

.ant-notification {
  z-index: 1070 !important;
}

.ant-pagination-options {
  display: none;
}

.ant-popover {
  z-index: 1060;
}

.anticon {
  vertical-align: 0em !important;
}

.navlinkClassName > div {
  background: #16a08550;
  border-right: 3px solid #16a085;
  color: #16a085;
}

.partnerNavlinkClassName {
  border-bottom: 3px solid #16a085;
}

.modal-title-center .ant-modal-title {
  text-align: center;
}

.ant-modal-close {
  display: none;
}

.modal-title-center .ant-modal-footer,
.modal-title-center .ant-modal-header {
  border: none !important;
  text-align: center;
}

@media only screen and (max-width: 1600px) {
  .ant-steps-item-title {
    font-size: 14px;
  }
}

.ant-picker-now-btn {
  display: none;
}

.react-images__view-image {
  max-height: 100vh !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #16a085;
  color: white;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #16a08550;
}

.DayPicker-Week {
  line-height: 1;
}

@media screen and (max-width: 400px) {
  .oro-offer .ant-table {
    overflow-x: auto !important;
    width: 325px !important;
  }
}

.simple-table thead > tr > th {
  background-color: rgba(51, 51, 51, 0.1) !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.primary-table thead > tr > th {
  background-color: rgba(255, 195, 81, 0.3) !important;
  color: black !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.oro-offer .ant-table-cell {
  border: 0.01em solid rgba(205, 205, 205, 0.7) !important;
}

.oro-offer.ant-table-selection-column {
  width: 2% !important;
}

.oro-offer .ant-checkbox-wrapper {
  /*flex: 1;*/
  align-items: center !important;
  justify-content: center !important;
}

/* tbody > tr > td {
  font-weight: 700 !important;
  color: black !important;
} */

.ant-table-cell{
  z-index: 0.5;
}
.upgraded-primary-bg {
  background-image: linear-gradient(
    180deg,
    #f9ca36 0%,
    #f8b830 100%
  ) !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.12);
}

.upgraded-secondary-bg {
  background-color: rgba(255, 195, 81, 0.3) !important;
}

.upgraded-primary-color {
  color: #ffc351 !important;
}

.upgraded-selector:hover {
  color: #ffc351;
}

.table__pagination {
  width: 90% !important;
}

.upgraded-primary-btn {
  background-color: #ffc351;
  color: black;
}

.upgraded-success-btn {
  background-color: #16a085;
  color: white;
}

.upgraded-success-btn:hover {
  background-color: #20c5a4;
  cursor: pointer;
}

.upgraded-primary-btn:hover {
  cursor: pointer;
  background-color: #edb040;
}

.upgraded-danger-btn {
  background-color: #cf2121;
  color: white;
}

.upgraded-danger-btn {
  background-color: #cf2121;
  color: white;
}

.upgraded-disabled-btn {
  background-color: #cecece;
  color: #919191;
  cursor: not-allowed;
}

.upgraded-danger-btn:hover {
  cursor: pointer;
  background-color: #e42e2e;
}

.ant-modal-title {
  padding-top: 15px !important;
  font-weight: 800 !important;
  font-size: 18px !important;
  margin-left: 12px !important;
}

.ant-modal-footer {
  border-top: 1px white !important;
}

.ant-modal-header {
  border-bottom: 1px white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#notifications-menu {
  height: 300px;
  overflow: scroll;
}

.ant-menu-item-selected {
  color: black;
}

/* li.ant-menu-item.ant-menu-item-active{
  color: rgba(17, 24, 39); 
}
.ant-menu-title-content:hover{
  color:black !important;
}
.ant-menu-item:hover  .ant-menu-title-content{
    color: rgba(17, 24, 39);
} 
.ant-menu-light .ant-menu-submenu-title:hover   {
  color: rgba(17, 24, 39);
} */
li.ant-menu-item.ant-menu-item-selected::after {
  transform: scaleY(0);
  opacity: 0;
}
.ant-menu-item-selected {
  border-left: 5px solid #ffc351;
}

.ant-menu-submenu-title {
  color: rgba(17, 24, 39) !important;
}

.menu-item-selected {
  border-left: 5px solid #ffc351;
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: rgba(17, 24, 39);
}
.ant-table-thead {
  -webkit-box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.08) !important;
}

th.ant-table-cell {
  background-color: #ffe1a8 !important;
}

/* Make the table column line break invisible  */
th.ant-table-cell::before {
  content: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td , 
.ant-table-tbody > tr > td.ant-table-cell-row-hover{
  background-color: transparent !important;
}
thead > tr > th {
  color: black !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
  border: none !important;
}

.ant-table-content {
  background-color: rgb(243, 244, 246) !important;
}
.ant-table-row {
  background-color: white !important;
}

.ant-table-tbody > tr:hover {
  outline: 2px solid rgb(239, 190, 80) !important;
}
td.ant-table-column-sort {
  background-color: white !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.ant-pagination-prev {
  background-color: transparent;
}
.ant-pagination-prev > .ant-pagination-item-link {
  border: none !important;
  background-color: transparent;
}

.ant-pagination-item {
  border: 1px solid gray;
  background-color: transparent;
  border-radius: 100%;
}
.ant-pagination-item-active {
  background-color: black;
}
.ant-pagination-item-active > a {
  color: white;
}
.ant-pagination-item-active:hover > a {
  color: white;
}
.ant-pagination-next > .ant-pagination-item-link {
  border: none !important;
  background-color: transparent;
}

/* 
.filter-search > .ant-input-wrapper > .ant-input{ 
  border-radius: 0.5rem 0 0  0.5rem ;
  border-right: none;
  padding: 10px;
}
.ant-input-search-button{
  border-left:none; 
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: transparent;
  padding: 10px;
}
  */

.ant-picker-content > thead > tr > th {
  background-color: #ffe1a8 !important;
  /* color: white !important; */
}
.ant-modal-content {
  border-radius: 20px !important;
}
/* span.ant-select-selection-placeholder{
    font-style: normal !important;
font-weight: bold !important;
font-size: 16px !important; 
line-height: 22px !important;
color: black !important;
  } */

.ant-picker > .ant-picker-input > input {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected
  > .ant-picker-time-panel-cell-inner {
  background-color: #ffe1a8 !important;
}

.ant-menu-item-selected {
  border-left: none;
}
.ant-menu-title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.ant-menu-title-content {
  border-left: 5px solid transparent;
}

.ant-menu-sub  > .ant-menu-item-selected > .ant-menu-title-content {
  padding-top: 10px;
  padding-bottom: 8px ;
  background-color: #FFF4DF;
  border-radius: 16px;
}

.ant-menu-submenu-title > .ant-menu-title-content {
  border-left: none;
  padding: 0px;

}
.submenuBar > .ant-menu-sub {
  border-left: 1px solid #E3E3E3;
  margin-left: 45px;
  width: 84%;
  padding-left: 10px;
}

.submenuBar > .ant-menu-sub > .ant-menu-item  {
   position: relative;
   left: -40px;
}

.ant-menu-submenu-title {
  margin-left: 15px !important;
} 

.ant-select-selection-search > input.ant-select-selection-search-input {
  margin-top: 8px !important;
}
.ant-select > .ant-select-selector {
  min-height: 45px !important;
  max-height: 1000px !important;
}

.ant-select > .ant-select-selector > .ant-select-selection-item {
  margin-top: 5px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

span.ant-select-selection-placeholder {
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgb(158, 157, 157) !important;
}
.ant-select-multiple
  > .ant-select-selector
  span.ant-select-selection-placeholder {
  margin-top: 0px !important;
}
.ant-select-selector
  > .ant-select-selection-overflow
  > .ant-select-selection-overflow-item {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.ant-menu-submenu-arrow {
  margin-right: 30px;
  color: '#909090';
}

input[type='checkbox'] {
  height: 25px;
}
input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgb(158, 157, 157) !important;
}
textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgb(158, 157, 157) !important;
}

select::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgb(158, 157, 157) !important;
}
input {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.grey-text {
  color: rgb(170, 170, 170);
  font-weight: 700;
  font-size: larger;
}

div.ant-upload-list-text-container {
  overflow: hidden;
  width: 200px;
}
div.ant-upload-list-text.ant-upload-list {
  overflow: hidden;
  max-width: 180px;
}
button:focus {
  outline: none !important;
}
.content {
  min-height: calc(100vh - 80px) !important;
  max-height: calc(100vh - 80px) !important;
  overflow-y: scroll !important;
}
.error {
  color: rgba(255, 101, 66, 1);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.ant-menu.ant-menu-root {
  border: none;
}
.empty {
  height: 100vh;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: #5A953E;
  border-radius: 4px;
}

.ant-checkbox .ant-checkbox-inner {
  border: 2px solid rgba(144, 144, 144, 1);
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #5A953E !important;
}
@media only screen and (max-width: 1600px) {
  body {
    zoom: 80% !important;
  }
  .content {
    min-height: calc(125vh - 80px) !important;
    max-height: calc(125vh - 80px) !important;
    overflow-y: scroll !important;
  }
  .empty {
    height: 125vh;
  }
}

@keyframes spinUp {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Spinner class */
.spinner-new {
	display: inline-block;
	animation: spinUp 2s linear infinite;
}

.hover-change:hover .text-label {
	color: #FFAA07;
	transition: color 0.3s; /* Optional: smooth transition */
}

.hover-change-new:hover {
	color: #FFAA07;
}